import { Avatar, Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { readImageFromS3 } from "../../functions/readImageFromS3";
import { useQuery } from "@tanstack/react-query";
import { getBirdsWithCount } from "../../api/exploreApi";
import { styled } from '@mui/system';

import InfiniteSlider, { ImageItem } from "../pages/about/InfiniteSlider";
import { Navigate, useNavigate } from "react-router-dom";
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';


const CardImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

function About() {
  const navigate = useNavigate();


  const { isLoading: showBirdsLoading, data: showBirds } = useQuery<ImageItem[]>({
    queryKey: ["showbirds"],
    queryFn: () => getBirdsWithCount(30),
  });
  return (
    <Box sx={{
      mt: '-120px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}>

      {/* 석양 */}
      <Box
        sx={{
          width: "100%",
          height: { xs: "0", md: "100vh" },
          position: "relative",
          zIndex: -1
        }}
      >
        <img
          src="./store/about/about.png"
          alt="Example"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
        <img
          src="./store/about/rectangle.png"
          alt="Example"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />

        <Box
          sx={{
            position: "absolute",
            top: "90%",
            left: "85%",
            transform: "translate(-50%, -50%)", // 좌우, 위아래 중앙 정렬
            color: "white", // 텍스트 색상
            textAlign: "center", // 텍스트 가운데 정렬
          }}
        >
          <Typography
            fontSize={12}
            sx={{ color: "#D9D9D9" }}
            fontFamily='Mazzard'
          >
            All photos taken by SparkBird Korea
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: "90%",
          left: "50%",
          transform: "translate(-50%, -50%) rotate(-90deg)",
          cursor: 'pointer',
        }}
        onClick={() => {
          window.scrollBy({
            top: window.innerHeight, // 화면 크기만큼 스크롤
            behavior: 'smooth', // 부드럽게 스크롤
          });
        }}
      >
        <ArrowBackIosRoundedIcon sx={{ fontSize: 24, color: '#D9D9D9' }} />
      </Box>




      {/* 내용 */}
      <Box
        sx={{
          backgroundColor: "black",
          color: "white",
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            margin: "5vh",
            display: { xs: "none", sm: "block", md: "flex" },
          }}>
          <img
            src="/store/logo.png"
            style={{ width: "36px", height: "auto" }}
          />
        </Box>
        <Box width='100vw' >
          <Typography sx={{
            fontSize: {
              xs: "20px",
              sm: "26px",
              md: "32px",
              lg: "36px",
              xl: "40px",
            }
          }} align="center" fontFamily="MazzardM-Bold" my={3}>
            LEADING BIRDWATCHING TOUR COMPANY IN KOREA
          </Typography>
          <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "12px", lg: "14px", xl: "16px" } }} align="center" fontFamily="MazzardM-Regular">
            SparkBird Korea is the premier birdwatching tour company in Korea, dedicated to those who love nature and birds.
            <br />
            Our name is inspired by the term 'Spark bird,' which refers to the bird that first ignited a person's interest in birdwatching.
            <br />
            We aim to be your companion on your first steps into the fascinating world of birdwatching in Korea.
          </Typography>
        </Box >

        <Box>
          <Typography sx={{
            fontSize: { xs: "16px", sm: "20px", md: "24px", lg: "28px", xl: "32px" },
            marginTop: { xs: "10vh", sm: "15vh", md: "20vh", lg: "25vh", xl: "25vh" },
          }} align="center" fontFamily="MazzardM-Bold">
            WHAT WE OFFER
          </Typography>


          {/* 관찰 조류 리스트 */}
          <Typography sx={{
            fontSize: { xs: "14px", sm: "16px", md: "18px", lg: "20px", xl: "22px" },
            marginTop: { xs: "5vh", md: "10vh", xl: "15vh" },
          }} align="center" fontFamily="MazzardM-Bold">
            600+ SPECIES TO DISCOVER
          </Typography>
          <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "12px", lg: "14px", xl: "16px" } }} align="center" fontFamily="MazzardM-Regular" my={1}>
            We offer access to over 600 species in Korea, including rare endemics like the Fairy Pitta and seasonal migrants like the Hooded Crane.
            <br />
            SparkBird Korea takes you to exclusive hotspots, ensuring you have the best opportunities to encounter these remarkable birds.
          </Typography>
          <Box sx={{
            maxWidth: "100vw",
            margin: "30px auto",
            overflow: "hidden",
          }}>
            {showBirdsLoading ? null : <InfiniteSlider images={showBirds || []} />}
          </Box>
        </Box>

        {/* 봄 겨울 */}
        <Typography sx={{
          fontSize: { xs: "14px", sm: "16px", md: "18px", lg: "20px", xl: "22px" },
          marginTop: { xs: "5vh", md: "10vh", xl: "15vh" },
        }} align="center" fontFamily="MazzardM-Bold" mt={10}>
          TAILORED TOURS TO MAXIMIZE YOUR BIRD LIST
        </Typography>
        <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "12px", lg: "14px", xl: "16px" } }} align="center" fontFamily="MazzardM-Regular" my={1}>
          Our tours are designed with precision to help you add the most species to your life list.
          <br />
          By aligning itineraries with migration patterns, seasonal peaks, and habitat accessibility,
          <br />
          SparkBird Korea ensures an optimized birding experience that brings you closer to your birding goals.
        </Typography>
        <Box
          display='flex'
          flexDirection='row'
          alignContent='center'
          justifyContent='center'
          marginX={1}
        >
          <SeasonBirding url="./store/about/Winter-Birding.png" title="Winter Birding" description="Sihwa Lake, Siheung-si, Gyeonggi-do, Republic of Korea" />
          <SeasonBirding url="./store/about/Spring-Birding.png" title="Spring Birding" description="Hogok-ri, Ujeong-eup, Hwaseong-si, Gyeonggi-do, Republic of Korea" />
        </Box>


        {/* ========= 가이드 프로필 ========= */}
        <Typography sx={{
          fontSize: { xs: "14px", sm: "16px", md: "18px", lg: "20px", xl: "22px" },
          marginTop: { xs: "5vh", md: "10vh", xl: "15vh" },
        }} align="center" fontFamily="MazzardM-Bold" >
          DECADES OF EXPERTISE
        </Typography>
        <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "12px", lg: "14px", xl: "16px" } }} align="center" fontFamily="MazzardM-Regular" my={1}>
          With over 30 years of field experience, our guides bring unmatched knowledge of Korea's birdlife and habitats.
          <br />
          SparkBird Korea provides not only access to species, but also deep insights into bird behavior and ecological significance, enriching every moment of your journey.
        </Typography>

        <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "12px", lg: "14px", xl: "16px" } }} align="center" fontFamily="MazzardM-Bold" my={5}>
          OUR GUIDES
        </Typography>

        < Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <ProfileCard name="Kim, Dae-hwan" role="Birder & Guide" url="/store/guides/daehwan.png" />
          <ProfileCard name="Park, Sang-jun" role="Birder & Guide" url="/store/guides/sangjun.png" />
          <ProfileCard name="Cho, Heung-sang" role="Birder & Guide" url="/store/guides/oscar.png" />
        </Box >


        {/* ========= 여름 가을 ========= */}
        <Typography sx={{
          fontSize: { xs: "14px", sm: "16px", md: "18px", lg: "20px", xl: "22px" },
          marginTop: { xs: "5vh", md: "10vh", xl: "15vh" },
        }} align="center" fontFamily="MazzardM-Bold" mt={10}>
          STRESS-FREE, CUSTOM-BUILT BIRDING
        </Typography>
        <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "12px", lg: "14px", xl: "16px" } }} align="center" fontFamily="MazzardM-Regular" my={1}>
          At SparkBird Korea, we handle all the logistics, so you can focus solely on birding.
          <br />
          From securing permits for private reserves to creating customized itineraries, our seamless service ensures a productive and stress-free experience.
        </Typography>

        <Box
          display='flex'
          flexDirection='row'
          alignContent='center'
          justifyContent='center'
          marginX={1}
          marginBottom={10}
        >
          <SeasonBirding url="./store/about/Autumn-Birding.png" title="Autumn Birding" description="Seosan, South Chungcheong Province, Republic of Korea" />
          <SeasonBirding url="./store/about/Summer-Birding.png" title="Summer Birding" description="Yubu Island, Songrim-ri, Janghang-eup, Seocheon-gun, Chungcheongnam-do, Republic of Korea" />
        </Box>

        {/* ========= 투어연결 ========= */}
        <Box sx={{
          width: "100%",
          height: "18vw",
          backgroundColor: "#101010",
          color: "white",
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <Box
            sx={{
              my: "20px",
              display: { xs: "none", sm: "block", md: "flex" },
            }}>
            <img
              src="/store/logo.png"
              style={{ width: "36px", height: "auto" }}
            />
          </Box>
          <Box width='100vw' >
            <Typography sx={{
              fontSize: { xs: "14px", sm: "16px", md: "18px", lg: "20px", xl: "22px" },
            }} align="center" fontFamily="MazzardM-Bold">
              LET’S FIND YOUR SPARK BIRD IN KOREA
            </Typography>
            <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "12px", lg: "14px", xl: "16px" } }} align="center" fontFamily="MazzardM-Regular">
              With over 500 species waiting to be discovered, Korea is the perfect destination to expand your life list.
              <br />
              SparkBird Korea is here to guide you every step of the way, ensuring an unforgettable birding journey tailored just for you.
            </Typography>
          </Box >

          <Button
            sx={{
              fontFamily: "MazzardM-Bold",
              color: "black",
              backgroundColor: "white",
              borderRadius: "30px",
              height: "36px",
              fontSize: '15px',
              width: '160px',
              my: "20px",
              '&:hover': {
                backgroundColor: '#D9D9D9'
              },
            }}
            onClick={() => {
              navigate('/tour');
            }}
          >
            Tour
          </Button>
        </Box>

      </Box >
    </Box >
  );
  type SeasonProps = {
    title: string;
    description: string;
    url: string;
  };
  function SeasonBirding({ title, description, url }: SeasonProps) {
    return <Box sx={{
      minWidth: "200px",
      width: "50%",
    }}>
      <img
        src={url}
        alt="Example"
        style={{
          height: "auto",
          objectFit: "cover",
          padding: 3
        }}
      />
      <Typography sx={{ fontSize: { xs: "9px", sm: "14px", } }} fontFamily="Mazzard" mt={1}>
        {title}
      </Typography>
      <Typography sx={{ fontSize: { xs: "8px", sm: "12px", } }} fontFamily="MazzardM-Light">
        {description}
      </Typography>
    </Box>
  }


  type ProfileProps = {
    name: string;
    role: string;
    url: string;
  };
  function ProfileCard({ name, role, url }: ProfileProps) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: '13vw',
          minWidth: '130px',
          padding: 2,
        }}
      >
        {/* 동그란 얼굴 사진 */}
        <Avatar
          alt={name}
          src={url} // 여기에 실제 이미지 URL을 넣으세요.
          sx={{
            width: '10vw',
            height: '10vw',
            minWidth: '100px',
            minHeight: '100px',
            marginBottom: 2,
          }}
        />

        {/* 사람 이름 */}
        <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "12px", lg: "14px", xl: "16px" } }} fontFamily="MazzardM-Medium"  >
          {name}
        </Typography>

        {/* 사람 설명 */}
        <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "12px", lg: "14px", xl: "16px" } }} fontFamily="MazzardM-Regular" color="gray" >
          {role}
        </Typography>
      </Box >
    );
  }

}

export default About;
