
import TourCard from "./../pages/tour/TourCard";
import { Typography, Container, Divider } from "@mui/material";
import * as React from "react";
import SideDrawer from "../pages/tour/components/SideDrawer";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { theme } from "../../theme-colors/theme";
import { ITour, getAllTours } from "../../api/exploreApi";
import { useQuery } from "@tanstack/react-query";
import { Data } from "@react-google-maps/api";

function Tour() {

  const { isLoading: tourLoading, data: tours } = useQuery<ITour[]>({
    queryKey: ["tours"],
    queryFn: () => getAllTours(),
  });

  return (
    <Box
      sx={{
        mt: "50px",
        width: "100%",
        minHeight: { xs: "0", md: "100vh" }
      }}
    >
      <Grid container justifyContent="space-between" paddingX="10%">
        <Grid item xs={0}>
          {/* <SideDrawer /> */}
        </Grid>
        <Grid item xs={12} justifyContent="center" >
          <Box
            sx={{
              color: theme.textColor,
              border: 0,
              mx: 3
            }}
          >
            <Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "18px", xl: "20px" } }} fontFamily="MazzardM-Medium" my={1}>
              {tours?.length} Tours
            </Typography>
          </Box>

          <Container
            maxWidth={false}
            sx={{
              alignmentBaseline: "true",
              maxWidth: "100%",
            }}>
            {tourLoading ? (
              <div>Loading...</div>
            ) : (
              tours?.map((tour: ITour) => (
                <TourCard key={tour.id} {...tour} />
              ))
            )}
          </Container>
        </Grid>
      </Grid>
    </Box >
  );
}
export default Tour;
