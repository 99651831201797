import * as React from 'react';
import Table from '@mui/joy/Table';
import { IBird, deleteBird, getAllAreas, getAllBirds, getBirdById, getBirdsByPlaceId, registBird } from '../../../api/exploreApi';
import { DataGrid, GridColDef, GridRowParams, GridRowSelectionModel } from '@mui/x-data-grid';
import { TextField, Box, Container, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

interface BirdsListProps {
  placeId: string;
  handleRowClick: (params: string[]) => void;
}

export interface IDataGrid {
  id: number
  birdId: string
  name: string
}

export default function BirdListForPlace(props: BirdsListProps) {

  const { isLoading: birdLoading, data: fetchBirdData, refetch } = useQuery<IBird[]>({
    queryKey: ["birdsAll"],
    queryFn: () => getAllBirds(),
  });
  const { isLoading: filteredBirdLoading, data: filteredBirdData, refetch: filteredBirdRefetch } = useQuery<IBird[]>({
    queryKey: ["filteredBirds"],
    queryFn: () => getBirdsByPlaceId([props.placeId]),
    enabled: !!props.placeId,
  });

  const columns: GridColDef<IDataGrid>[] = [
    { field: 'name', headerName: 'name', width: 300, },
    { field: 'birdId', headerName: 'birdId', width: 500 },
  ];

  const [searchText, setSearchText] = useState<string>("");
  const [pageSize, setPageSize] = useState<number>(5);
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  const [rows, setRows] = useState<IDataGrid[]>([]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleSelectedRows = (newSelectionModel: GridRowSelectionModel) => {
    setSelectedRows(newSelectionModel);
    if (newSelectionModel.length > 0) {
      const selectedIds = newSelectionModel as number[];
      const birdsToRegist = rows.filter(row => selectedIds.includes(row.id));

      props.handleRowClick(birdsToRegist.map(bird => bird?.birdId));
    }
  }

  const handleRegist = () => {
    const selectedIds = selectedRows as number[];
    const birdsToRegist = rows.filter(row => selectedIds.includes(row.id));
    birdsToRegist.map(bird => {
      const registBirdToPlace = async () => {
        const birdPlace = await registBird(
          props.placeId, bird?.birdId
        );
      };
      registBirdToPlace();
    });
  }

  useEffect(() => {
    const filteredRows = fetchBirdData?.filter((bird) =>
      bird.name.toLowerCase().includes(searchText.toLowerCase())
    ).map((bird, index) => ({
      id: index + 1, // or another unique identifier
      birdId: bird.id,
      name: bird.name,
    })) || [];

    setRows(filteredRows);
  }, [searchText, fetchBirdData]);

  useEffect(() => {
    if (filteredBirdData) {
      const selectedIds = filteredBirdData.map(bird => {
        const row = rows.find(row => row.birdId === bird.id);
        return row ? row.id : -1;
      }).filter(id => id !== -1);
      setSelectedRows(selectedIds);
      console.log(selectedIds)
    }
  }, [filteredBirdData, rows]);

  useEffect(() => {
    filteredBirdRefetch()
  }, [props.placeId])

  return (
    <Container>
      <Box marginBottom={2}>
        <TextField
          label="Search"
          variant="outlined"
          fullWidth
          onChange={handleSearch}
          value={searchText}
        />
      </Box>
      <Box sx={{ height: 400, width: '100%' }} marginBottom={2}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          rowSelectionModel={selectedRows}
          pageSizeOptions={[5]}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={handleSelectedRows}
        />
      </Box>
      <div>{props.placeId}</div>
      <Box marginBottom={2} columnGap={2}>
        <Button
          sx={{ marginRight: "5px" }}
          variant="contained"
          color="success"
          onClick={handleRegist}
          disabled={selectedRows.length === 0}
        >
          등록
        </Button>
      </Box>
    </Container>
  );
}
