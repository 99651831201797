import styled from "styled-components";
import { ThreeDots } from "../../components/icons/ThreeDots";
import { Box, Container, Grid, Link, Typography } from '@mui/material';
import { theme } from "../../theme-colors/theme";
import { useLocation } from "react-router-dom";

function Footer() {
  return (
    <Box
      sx={{
        width: '100%',
        color: theme.lightColor,
        py: 4,
        px: '10% !important',
        bgcolor: theme.textColor
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.textColor,
          p: '0 !important',
          m: '0 !important',
        }}>
        <Box sx={{
          display: "flex",
          justifyContent: "space-between"
        }}>
          <Box sx={{
            flexDirection: "column"
          }}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={4} sx={{ minWidth: '200px' }} >
                <Typography gutterBottom sx={{ mb: 2 }} fontFamily="MazzardM-Regular" fontSize="14px">
                  About Us
                </Typography>
                {/* <Box>
                  <Link href="" color="inherit" underline="hover" sx={{ display: 'block', mb: 0.01 }} >
                    <Typography fontFamily="MazzardM-Regular">
                      SparkBird Korea
                    </Typography>
                  </Link>
                  <br />
                  <Link href="contact" color="inherit" underline="hover" sx={{ display: 'block', mb: 0.01 }}>
                    <Typography fontFamily="MazzardM-Regular">
                      Contact Us
                    </Typography>
                  </Link>
                </Box> */}
              </Grid>
              <Grid item xs={12} sm={6} md={4} sx={{ minWidth: '200px' }}>
                <Typography gutterBottom sx={{ mb: 2 }} fontFamily="MazzardM-Regular" fontSize="14px">
                  Support
                </Typography>
                <Box>
                  <Link href="#" color="inherit" underline="hover" sx={{ display: 'block', mb: 0.01 }} fontFamily="MazzardM-Regular" fontSize="12px">
                    FAQ
                  </Link>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} sx={{ minWidth: '200px' }} >
                <Typography gutterBottom sx={{ mb: 2 }} fontFamily="MazzardM-Regular" fontSize="14px">
                  Terms
                </Typography>
                <Box>
                  <Link href="#" color="inherit" underline="hover" sx={{ display: 'block', mb: 0.01 }} fontFamily="MazzardM-Regular" fontSize="12px">
                    Terms of Use
                  </Link>
                  <br />
                  <Link href="#" color="inherit" underline="hover" sx={{ display: 'block', mb: 0.01 }} fontFamily="MazzardM-Regular" fontSize="12px">
                    Privacy Policy
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              alignContent: "center",
              display: { xs: "none", sm: "block" },
            }}>
            <img
              src="/store/logo_w.png"
              style={{ width: "120px", height: "auto" }}
            />
          </Box>

        </Box>
        <Box textAlign="left" mt={4} flexDirection="row" display="flex" justifyContent="space-between" width="100%">
          <Typography gutterBottom sx={{ mb: 2 }} fontFamily="MazzardM-Regular" fontSize="10px">
            Copyright &copy; {new Date().getFullYear()} SparkBird Korea All rights reserved.
          </Typography>
          <Box flexDirection="row" display="flex" mx="10px" columnGap="20px">
            <Link href="https://www.facebook.com/profile.php?id=61572859310329" target="_blank" color="inherit" underline="hover" sx={{ display: 'block', mb: 0.01 }} fontFamily="MazzardM-Regular" fontSize="12px">
              Facebook
            </Link>
            <Link href="https://www.instagram.com/sparkbird_korea/" target="_blank" color="inherit" underline="hover" sx={{ display: 'block', mb: 0.01 }} fontFamily="MazzardM-Regular" fontSize="12px">
              Instagram
            </Link>
            <Link target="_blank" color="inherit" underline="hover" sx={{ display: 'block', mb: 0.01 }} fontFamily="MazzardM-Regular" fontSize="12px">
              YouTube
            </Link>
            <Link href="https://www.linkedin.com/company/sparkbird-korea/" target="_blank" color="inherit" underline="hover" sx={{ display: 'block', mb: 0.01 }} fontFamily="MazzardM-Regular" fontSize="12px">
              LinkedIn
            </Link>
          </Box>
        </Box>
      </Box>
    </Box >
  );
}
export default Footer;
