import Box from "@mui/material/Box";
import { Avatar, Typography } from "@mui/material";

import { IBird, ITour, getTopBirdById } from "../../../../api/exploreApi";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import Timeline from "@mui/lab/Timeline";
import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, timelineItemClasses, TimelineSeparator } from "@mui/lab";

export default function TourGuide(tour: ITour) {


    const { isLoading: birdLoading, data: topBirdData } = useQuery<IBird[]>({
        queryKey: ["ovv" + tour.id],
        queryFn: () => getTopBirdById(tour.id),
    });
    const storyDaehwan = `After studying algae physiology at university and working as a high school biology teacher, he developed an interest in wildflowers, insects, and mushrooms. However, in January 2002, he accidentally spotted a bird, was captivated by its beauty, and has been birding ever since. \n
                            At that time, Korea's birding environment was very rudimentary, with little literature available for reference. Despite this, he actively pursued birding, photographing birds for publications and educational purposes. Over the years, he has authored five birding books and led various birding-related educational activities. \n
                            With support from the Ministry of Environment, he ran a birding education program for four years. Additionally, with support from Incheon City, he conducted bird monitoring on Incheon's islands and carried out foundational research for birding tourism. \n
                            He also founded the Incheon Birding Association, a civic organization dedicated to bird conservation, birding tourism, and birding education. For nine years, he led the Winter Bird Census, a project promoted by the Ministry of Environment.
                            
                            大学期间研究藻类生理学，毕业后担任高中生物老师。在此期间，他对野花、昆虫和蘑菇产生了兴趣。然而，在2002年1月，他偶然看到一只鸟，被它的美丽深深吸引，从此开始了观鸟之旅。

                            当时，韩国的观鸟环境非常简陋，几乎没有参考资料。尽管如此，他依然积极投身于观鸟活动，并为出版物和教育用途拍摄鸟类照片。多年来，他撰写了五本与观鸟相关的书籍，并开展了各种观鸟教育活动。

                            在环境部的支持下，他运营了为期四年的观鸟教育项目。此外，他还得到了仁川市的支持，对仁川周边岛屿进行了鸟类监测，并为发展观鸟旅游奠定了基础研究。

                            他还创立了仁川观鸟协会，这是一个致力于鸟类保护、观鸟旅游和观鸟教育的市民组织。他曾连续九年领导由环境部推动的冬季鸟类普查项目。

                            
                            `

    const storyLiam = `Liam has been a passionate birdwatcher for over 20 years, with a deep love for the diverse avian species found in South Korea. His birdwatching journey began in Cheonsu Bay, Seosan, where he became captivated by the region’s unique birdlife.\n
                            Having observed more than 430 bird species across South Korea, he has developed an exceptional understanding of the country’s bird habitats and migration patterns.\n
                            Liam's experience extends beyond local birdwatching—he has participated in international birding tours, including expeditions in Mongolia, Thailand, and the Philippines.\n
                            With his meticulous planning and vast knowledge of bird species, Liam offers birdwatchers a truly unforgettable experience, guiding them to encounter both common and rare birds across the beautiful landscapes of South Korea.
                            
                            我从事炼油厂土木工程设计工作已经超过35年。  
                            由于炼油厂的特殊性质，建造或升级复杂、网状的工艺设施需要极其细致的规划和安全的执行。  
                            我一直践行“细致规划与安全观鸟”的理念。这种通过多年经验形成的思维方式已经成为我的生活方式，并且是我日常习惯的核心。  

                            小时候，我在观察鸣禽和水鸟巢穴的过程中对鸟类生态产生了兴趣。即使到现在，当我观察和研究鸟类时，我依然感觉自己像是一个充满好奇又谨慎的孩子。  

                            我成年后开始认真观鸟的地方是瑞山的天穗湾（Cheonsu Bay），不知不觉间，我的观鸟生涯已经跨越了20多年。  
                            我在韩国观察了超过430种鸟类，几乎覆盖了全国的大部分地区。我的国际观鸟经历始于2016年的蒙古，随后是2017年泰国清迈，2018年泰国清迈和清莱，2019年菲律宾，以及2023年在泰国进行的一次全国范围的观鸟旅行。  

                            区分完全不同的鸟类物种相对容易。然而，仅在韩国，就有十多种鹀类和数十种鹬类。有些鸟类非常相似，即使近距离观察，也极难区分。  

                            通过践行“细致规划与安全观鸟”，我希望为来自国外的观鸟者提供一种通过望远镜锁定多样而生动的野生鸟类目光的喜悦体验——这将是一次真正难忘的邂逅。

                            
                            `

    const storyOscar = `Oscar is a passionate birdwatcher and expert bird guide, with over 20 years of experience in the field. He fell in love with birds after his first sighting in 2002 and has been actively involved in birding ever since.\n
                            Oscar has observed numerous bird species in South Korea, and his extensive knowledge of the country’s birdlife has made him an invaluable guide for both beginners and experienced birdwatchers. He specializes in interpreting bird behavior and sharing fascinating stories about various species.\n
                            Oscar’s expertise extends beyond local birdwatching—he has also participated in international birding events, including the Bird Races in both domestic and international settings.\n
                            With his years of experience and passion for birds, Oscar offers birdwatchers a unique and unforgettable experience. He guides visitors through Korea’s most beautiful bird habitats, providing insights into the behavior and characteristics of the birds that call this land home.
                            
                            Oscar是一位充满热情的观鸟者，同时也是一名经验丰富的鸟类向导，拥有超过20年的从业经验。他在2002年第一次观察到鸟类后便深深爱上了这些生物，并从此积极投身于观鸟活动。  

                            Oscar在韩国观察了众多鸟类物种，他对本国鸟类生态的广泛知识使他成为初学者和资深观鸟者不可或缺的向导。他擅长解读鸟类行为，并分享关于各种鸟类的有趣故事。  

                            Oscar的专业知识不仅限于本地观鸟——他还参与了国际观鸟活动，包括在国内外举行的“鸟类竞赛”（Bird Races）。  

                            凭借多年的经验和对鸟类的热情，Oscar为观鸟者提供了独特且难忘的体验。他带领游客穿越韩国最美丽的鸟类栖息地，深入讲解生活在这片土地上的鸟类的行为和特征。
`
    return <>
        <Box my={5} mx="10%">
            <Timeline
                sx={{
                    padding: 0,
                    [`& .${timelineItemClasses.root}:before`]: {
                        flex: 0,
                        padding: 0,
                    }
                }}>

                {/* <TimelineItem>
                    <TimelineSeparator >
                        <TimelineDot
                            sx={{
                                width: '30px', height: '30px',
                                padding: 0, borderWidth: 0, boxShadow: 0
                            }}
                        >
                            <img src="./../store/logo_b.png"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }} />
                        </TimelineDot>
                        <TimelineConnector />

                    </TimelineSeparator>
                    <TimelineContent sx={{
                        maxWidth: "100%",
                        paddingRight: 0
                    }}> */}
                <ProfileCard name="Kim, Dae-hwan" role="Birder & Guide" url="/store/guides/daehwan.png"
                    story={storyDaehwan} />
                <ProfileCard name="Park, Sang-jun (Liam)" role="Birder & Guide" url="/store/guides/sangjun.png"
                    story={storyLiam} />
                <ProfileCard name="Cho, Heung-sang (Oscar)" role="Birder & Guide" url="/store/guides/oscar.png"
                    story={storyOscar} />
                {/* </TimelineContent>
                </TimelineItem> */}
            </Timeline>
        </Box>
    </>
}

type ProfileProps = {
    name: string;
    role: string;
    story: string;
    url: string;
};
function ProfileCard({ name, role, url, story }: ProfileProps) {
    return (

        <TimelineItem>
            <TimelineSeparator >
                <TimelineDot
                    sx={{
                        width: '30px', height: '30px',
                        padding: 0, borderWidth: 0, boxShadow: 0
                    }}
                >
                    <img src="./../store/logo_b.png"
                        style={{
                            width: '100%',
                            height: '100%',
                        }} />
                </TimelineDot>
                <TimelineConnector />

            </TimelineSeparator>
            <TimelineContent sx={{
                maxWidth: "100%",
                paddingRight: 0
            }}>
                <Box sx={{ flexDirection: "row", display: "flex", flexWrap: "wrap" }}>
                    <Box alignContent="center" justifyContent="center" alignItems='center' my='10px'>
                        <Typography fontFamily="MazzardM-Medium" sx={{
                            fontWeight: 600,
                            fontSize: { xs: "14px", sm: "16px", md: "18px", lg: "20px", xl: "22px" },
                        }}>
                            {name}
                        </Typography>
                    </Box>
                    <Box sx={{ padding: "2%", flexDirection: { xs: "column", md: "row" }, display: "flex" }}>
                        <Box >
                            <Avatar
                                alt={name}
                                src={url} // 여기에 실제 이미지 URL을 넣으세요.
                                sx={{
                                    width: '10vw',
                                    height: '10vw',
                                    minWidth: '200px',
                                    minHeight: '200px',
                                    marginBottom: 2,
                                }} />
                        </Box>
                        <Box sx={{ paddingX: { xs: "0", md: "50px" }, flexDirection: "row", display: "flex", whiteSpace: 'pre-line' }}>
                            <Typography sx={{ fontFamily: { xs: "ACaslonPro-Regular" }, whiteSpace: 'pre-line', textAlign: 'justify', lineHeight: { xs: '1.2', md: '1.5' } }}>
                                {story}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </TimelineContent>
        </TimelineItem>


    );
}